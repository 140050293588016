import Config from 'legacy/config';
import $ from 'legacy/jquery';

/**
 * TODO: Duplicated from app/assets/javascripts/shared/ui/datepicker.js
 */

// @ts-expect-error - TS2339 - Property 'UI' does not exist on type 'Window & typeof globalThis'.
const UI = window.UI || {};
UI.Datepicker = {
  init: function (selector: string, options: object) {
    const datepicker = $(selector);
    datepicker.datepicker(
      $.extend(options, {
        dateFormat: Config.TimeZone.dateFormat('datepicker'),
      })
    );
    datepicker.datepicker('setDate', datepicker.val());
    $('#ui-datepicker-div').addClass('datepicker');
  },
};

UI.Tabs = UI.Tabs ?? {};

export default UI;
