/* eslint max-len: "off", quotes: "off", no-unused-vars: "off" */

/*
  TODO: Duplicated from app/assets/javascripts//message.js.erb
 */

import t from 'shared/utils/translation';

const uploaders = ['Dropbox', 'Google Drive'];

function uploaderMessages() {
  const hash: Record<string, unknown> = {};
  for (const uploader of uploaders) {
    const name = uploader;
    const key = name.replace(/\s/g, ''); // remove empty space in the uploader name as hash key
    hash[key] = {
      LoadingAttachment: 'Loading attachment from ' + name + '...',
      WithoutBrowserSupport:
        'The browser does not support ' + name + ' upload.',
      TryAgain:
        'Unable to load ' +
        name +
        '. It has either timed out or you can try again later.',
    };
  }
  return hash;
}

const Message = {
  Tooltip: {
    InterviewPlanCandidateGoal:
      'If you set a goal, Greenhouse will show your progress against this goal on your weekly reports.',
    InterviewPlanDwellTime:
      'Greenhouse can alert you when candidates are in a stage for too long, ensuring that no one falls through the cracks.',
    JobDetailsNumberOfOpenings:
      'If you would like to purchase more openings, you can do so under Subscription.',
    GreenhouseJobApplicationGuidance: t(
      'messages.tooltip.greenhouse_job_application_guidance'
    ),
    HRRole: 'Admin + EEOC/Private Candidates',
    Responsive: t('messages.tooltip.responsive'),
    Accessible: t('messages.tooltip.accessible'),
    NoAutoScroll: t('messages.tooltip.no_auto_scroll'),
    AutoLoad: t('messages.tooltip.auto_load'),
    JobBoardBaseUrl: t('messages.tooltip.job_board_base_url'),
    JobAppBaseUrl: t('messages.tooltip.job_app_base_url'),
    YourURLToken: t('messages.tooltip.your_url_token'),
    CustomCssUrl: t('messages.tooltip.custom_css_url'),
    BambooHrApiKey:
      'To find your API key in Bamboo, log in and click the link to "Hi, Your Name". The submenu will include a link to "API Keys". Click through and "Add a New Key".',
    BambooHrCompanySubdomain:
      'To find your company subdomain, log in to Bamboo and look at the URL in your browser. The subdomain is the part before ".bamboohr.com". For example, if the URL is "mycompany.bamboohr.com", then the company subdomain is "mycompany."',
    ResendInvite:
      'Click to re-send the email inviting this user to join your Greenhouse account.',
    DisableDataProtectionOfficer:
      'This user is currently set as the Data Protection Officer on the Account Settings > Contacts page. Please set another user before disabling this user.',
    AddAProspect:
      'If you don’t have a specific job for a prospect in mind, you can choose a department (and optionally office/level) and they’ll show up as a prospect on all corresponding jobs at once. <br/><br/>' +
      'If you know exactly which position(s) they’d be good for, you can choose one or more specific jobs individually.',
    ApprovalOpenJob: t('messages.tooltip.approval_open_job'),
    ApprovalOfferJob: t('messages.tooltip.approval_offer_job'),
    ApprovalOfferCandidate: t('messages.tooltip.approval_offer_candidate'),
    ApprovalTwoStage: t('messages.tooltip.approval_two_stage'),
    ApprovalOneStage: t('messages.tooltip.approval_one_stage'),
    ReportingQuality: t('messages.tooltip.reporting_quality'),
    MilestoneReportingQuality: t(
      'messages.tooltip.milestone_reporting_quality'
    ),
    ReportingTotalHours: t('messages.tooltip.reporting_total_hours'),
    ReportingExcludeAppReview:
      "If checked, we'll exclude Application Review (and Hiring Manager Application Review).",
    ReportingActivityDate:
      'This will filter each column uniquely by the date the event occurred.',
    TakeHomeTestSendLink:
      "By default, Greenhouse automatically includes a link to a page where candidates can directly upload their completed tests.  If you'd like them to submit their test another way (e.g. survey monkey, email address) simply check the box and the link won't be included in the email.<br/><br/>NOTE: If you do check the box, be sure to include instructions for how to submit their test in the body of the email.",
    TakeHomeTestGrading: t('messages.tooltip.take_home_test_grading'),
    EmailTokens: t('messages.tooltip.email_tokens'),
    JobAdminOrgRoleConfig:
      'Want to specify what Job Admins have access to without giving them full Site Admin permissions? Manage your own Job Admin levels here.',
    DefaultJobAdminPermissions:
      "<ul id='default-job-admin-permissions'><li>Can see the dashboard, pipeline, and reports</li><li>Can see all candidates</li><li>Can add and edit candidates and referrals</li><li>Can be assigned hiring team roles</li></ul>",
    JobPostPrivateQuestion:
      'Marking this question Private will allow only Job Admins and Site Admins with the "Private Data" permission to see candidate answers.' +
      '<br><br>Marking this question API Only will hide it from Greenhouse completely and allow only users accessing the Harvest API to see candidate answers. Marking questions API Only cannot be undone.',
    AgencyPrivateNotes:
      'Use this for confidential information to be seen by HR only (e.g. current/desired salary)',
    FollowJob:
      'Jobs you follow will show up under the "Jobs I\'m following" filter on your homepage',
    BulkAddToAnotherJob: 'Filter by "Candidates Only" to do this action.',
    BulkApplicationReview:
      'Filter for "Candidates Only" on a single job to do this action. Any candidates not in an application review stage will be skipped.',
    BulkCreateOffer: 'Filter by "Candidates Only" to perform this action.',
    BulkCreateOfferWithOpeningsOnOffers:
      'Cannot perform bulk offer creation due to "Openings on Offers" feature being enabled for your account.',
    BulkDeleteCandidatesPersonalData:
      'Filter by GDPR status "Data due to be deleted" (under Profile Details) to do this action',
    BulkRequestCandidatesConsent:
      'Filter by GDPR status "Consent not requested" (under Profile Details) to enable this action',
    BulkScheduleWithCalendly:
      'Filter by Job, Stage, and "To Be Scheduled" to do this action. All candidates must be in an interview stage with exactly 1 schedulable interview.',
    BulkGenerateOfferDocuments: 'Filter by "Offer Created" to do this action.',
    BulkPrintResumes:
      'Select a maximum of 30 applications. Applications without resumes will be skipped.',
    BulkProspectActions: 'Filter by "Prospect Only" to do this action.',
    BulkSendJobPosts:
      'Filter by Job and Job Post "To Be Sent" to do this action.',
    BulkSendForm:
      'Filter for "Candidates Only" on a single job to do this action. Any candidates without an email address will be skipped.',
    BulkSendTakeHomeTest:
      'Filter by Job, Stage and Test Status "To Be Sent" to do this action.',
    BulkTransferToDifferentJob:
      'Filter by "Candidates Only" on the same job to do this action.',
    BulkSendOffer:
      'Filter by "Candidates Only" and Offer "To Be Sent" to do this action.',
    JobBoardIndeedApply: t('messages.tooltip.job_board_indeed_apply'),
    JobBoardSeekApply: t('messages.tooltip.job_board_seek_apply'),
    WebHookBasicAuth: t('messages.tooltip.web_hook_basic_auth'),
    WebHookOauth: t('messages.tooltip.web_hook_oauth'),
    WebHookAdditionalHeaders: t('messages.tooltip.web_hook_additional_headers'),
    WebHookMaxAttempts: t('messages.tooltip.web_hook_max_attempts'),
    ConfidentialJob:
      "Confidential jobs will only be visible to you. You must add additional users to the job manually on the job's hiring team page.",
    TeamAndResponsibilities:
      "<ul><li> What will this person's role and responsibilities be? </li> <li> How will their success be evaluated? </li> <li> Who will they be working with? </li> <li> Who will they report to? </li><ul>",
    HowToSellThisJob:
      '<ul><li> How should employees sell this job to candidates? </li> <li> What are the challenges of this position? </li> <li> How is taking this job a good career move? </li> <li> What will the candidate learn in this role? </li> </ul>',
    ConnectToGoogleCalendar:
      'Click here to give Greenhouse permission to schedule events in your Google Calendar.',
    ConnectToOutlook365Calendar:
      'Click here to give Greenhouse permission to schedule events in your Outlook 365 Calendar',
    ImmutableFieldKeyNote:
      'This key is generated on creation of a custom field and will never change. It enables downstream systems, such as HRIS systems, to detect the presence of Greenhouse custom fields in exported data.',
    BulkEmailWarning: t('messages.tooltip.bulk_email_warning'),
    RejectBulkEmailWarning: t('messages.tooltip.reject_bulk_email_warning'),
    JobBoardLogoHref: t('messages.tooltip.job_board_logo_href'),
    CandidatesEntered: t('messages.tooltip.candidates_entered'),
    CandidatesExited: t('messages.tooltip.candidates_exited'),
    CandidatesRejected: t('messages.tooltip.candidates_rejected'),
    InterviewsCreated: t('messages.tooltip.interviews_created'),
    NumberOfInterviews: t('messages.tooltip.number_of_interviews'),
    CandidatesInterviewing: t('messages.tooltip.candidates_interviewing'),
    ScorecardsSubmitted: t('messages.tooltip.scorecards_submitted'),
    CandidatesRated: t('messages.tooltip.candidates_rated'),
    TakeHomeTestsSent: t('messages.tooltip.take_home_tests_sent'),
    TakeHomeTestsSubmitted: t('messages.tooltip.take_home_tests_submitted'),
    LinkedInLimitedListings: t('messages.tooltip.linked_in_limited_listings'),
    LinkedInCompanyUrl: t('messages.tooltip.linkedin_company_url'),
    GravatarSetting:
      'Candidates can create their Gravatars whenever they sign up with sites that use wordpress.com. <br> Gravatars are helpful as they give you a visual cue to remember candidates.',
    IDashAppReview: t('messages.tooltip.i_dash_app_review'),
    OfficeSpecificField:
      'This field will only be applied to the appropriate candidates.',
    LinkedinVerifyEmail:
      'Signing in with LinkedIn connects the post to your LinkedIn profile, allowing you to manage the post once published in LinkedIn and track all candidate views and apply clicks.',
    OfferApprovalFlowForExistingJobs: t(
      'messages.tooltip.offer_approval_flow_for_existing_jobs'
    ),
    CustomFieldOption:
      'Input custom field options, each on a new line. For example: <br> Option one <br> Option' +
      ' two <br> Option three',
    TalentwiseOrderedBy:
      'Contact the user who ordered this Talentwise background check for access to the report.',
    AddThisJob: t('messages.tooltip.add_this_job'),
    MoveThisJob: t('messages.tooltip.move_this_job'),
    ScorecardQuestionOption:
      'Enter question options, each on a new line. For example: <br> Option one <br> Option' +
      ' two <br> Option three',
    LinkedAttributes: t('messages.tooltip.linked_attributes'),
    CannotManageProspectPool:
      "You can only edit this pool if you're a pool admin",
    ProspectPool: t('messages.tooltip.prospect_pool'),
    ProspectStages: t('messages.tooltip.prospect_stages'),
    HideProspectPool:
      'Hide pools if you no longer want them to appear in prospect pool and stage dropdown.',
    OfferSectionToken: t('messages.tooltip.offer_section_token'),
    OfferSectionAvailableTokens: t(
      'messages.tooltip.offer_section_available_tokens'
    ),
    PipelinePerJobNow: t('messages.tooltip.pipeline_per_job_now'),
    PipelinePerJobEver: t('messages.tooltip.pipeline_per_job_ever'),
    DemographicPipelinePassRate:
      'Pass rate is the percentage of candidates who advanced to the following milestone.',
    DemographicPipelineMilestonePassRate:
      'Milestone pass rate is the overall percentage of candidates for the selected job(s) who advance to the following milestone.',
    MilestonePipelinePerJobNow: t(
      'messages.tooltip.milestone_pipeline_per_job_now'
    ),
    MilestonePipelinePerJobEver: t(
      'messages.tooltip.milestone_pipeline_per_job_ever'
    ),
    OfferExtended: t('messages.tooltip.offer_extended'),
    OfferOutstanding: t('messages.tooltip.offer_outstanding'),
    OfferAcceptanceRate: t('messages.tooltip.offer_acceptance_rate'),
    Adherence: 'The percent of focus attributes completed by the interviewer',
    AdobeSignTokens:
      'For multiple signers, use the same Adobe Acrobat Sign Tokens, but change the number in the token to match the associated signer.<br>For example, to add a second signer, use <strong>{{SIG_ES_:SIGNER2:SIGNATURE}}</strong>.',
    AdobeSignInputTokens:
      'For input tokens, change the number in the token to match the signer, like with the signature tokens. But you’ll also want to include any options or values for each.<br>For example, if you have one signer and want to give them a checkbox to agree, use <strong>{{CHECKBOX1_ES_:SIGNER1:CHECKBOX:LABEL("I agree")}}</strong>',
    DocusignDateTokens:
      'Older versions of the Docusign integration relied on a single {{SIGNATURE_DATE}} token. For the best results, we recommend using both {{CANDIDATE_SIGNATURE_DATE}} and {{COMPANY_SIGNATURE_DATE}} in your templates.',
    DocusignTextInputTokens:
      'For multiple text inputs, use the same Docusign Tokens, but change the number in the token.<br>For example, to add a second text input use <strong>{{CANDIDATE_TEXT_INPUT2}}</strong>.',
    InterviewerTags:
      "When scheduling an interview, you'll be able to filter users by tag to help decide who should conduct each interview.",
    LinkUserToCandidate:
      'Link users to candidates to prevent users from seeing their own candidate profile.',
    ArchivedProspectPool: t('messages.tooltip.archived_prospect_pool'),
    QuestionPrivacy: t('messages.tooltip.question_privacy'),
    FormsQuestionPrivacy: t('messages.tooltip.forms_question_privacy'),
    BulkEditJobPostDate:
      'Month can be required when year is required on all posts. Month can be optional when year is either optional or required on all posts.',
    UltiproServiceUsername:
      'This is the username used to access the API endpoints to fetch the list of onboarding owners and active jobs.',
    UltiproServicePassword:
      'This is the password used to access the API endpoints to fetch the list of onboarding owners and active jobs. This is used with the service username for HTTP Basic Auth',
    UltiproServiceKey:
      'This is an API key used in conjunction with the username and password to access onboarding owners and active jobs. All 3 are required to access these endpoints.',
    UltiProTenantAlias:
      "This is your company's unique tenant to access the Onboarding API.",
    UltiproServiceHostname:
      'This is the hostname your company uses to access the Service API. It should look like service.ultipro.com',
    UltiproOnboardingHost:
      'This is the hostname used to access the Onboarding API. This should look like onb.ultipro.com',
    UltiproRoleId:
      'This is the role id unique to your Ultipro instance that indicates Onboarding Owners. This allows Greenhouse to get only users with the Onboarding Owner Role.',
    PhantomLoginNoGrant:
      'The user has not granted temporary access to their account',
    ShorterDkimKey: t('messages.tooltip.shorter_dkim_key'),
    MissingApplicationRuleQuestions: t(
      'messages.tooltip.missing_application_rule_questions'
    ),
  },
  Billing: {
    Subscription: {
      Updating: 'Updating your subscription',
    },
  },
  Account: {
    OrgName: {
      Success: 'Organization name successfully updated',
      Failure: 'Failed to save organization name, please try again',
      Blank: "Organization name can't be blank",
    },
    OrgSize: {
      Success: 'Organization size successfully updated',
      Failure: 'Failed to save organization size, please try again',
    },
    RequisitionCounter: {
      Success: 'Requisition ID counter successfully updated',
      Failure: 'Failed to save Requisition ID counter, please try again',
      Invalid: 'Must be a number greater than 0',
    },
    DefaultDateFormat: {
      Success: 'Default Date Format successfully updated',
      Failure: 'Failed to save Default Date Format, please try again',
    },
    DefaultTimeFormat: {
      Success: 'Default Time Format successfully updated',
      Failure: 'Failed to save Default Time Format, please try again',
    },
    CloseJob: {
      Success: 'Job successfully closed',
      Failure: 'Failed to close job, please try again.',
    },
    ReopenJob: {
      Success: 'Job successfully re-opened',
      Failure: 'Failed to re-open job, please try again.',
    },
    OpenDraftJob: {
      Success: 'Job successfully opened',
    },
    NewUser: {
      EmailRequired: 'You must add at least one email address to continue',
    },
  },
  Person: {
    Show: {
      HeadlineDefault: 'Write a headline about this person ...',
      AddToJob: {
        Success: 'Added to job',
        Failure: 'Failed to add person to job',
      },
      UpdateSource: {
        Success: 'Source updated',
        Failure: 'Failed to update source',
      },
      UploadPhotoPending: 'Uploading photo...',
      DeletePhotoPending: 'Deleting photo...',
      Application: {
        LoadCustomFieldsFailed:
          'Failed to load custom fields. Please try refreshing the page.',
      },
    },
    Offer: {
      OfferDateRequired: 'Offer date is required',
    },
  },
  Home: {
    MustSelectJobType: 'Please make a selection',
  },
  RampUp: {
    Validation: {
      RoleRequired: 'A role must be selected',
      JobNameRequired: 'An internal job name is required',
      PublicJobNameRequired: 'An external job name is required',
    },
  },
  JobBoard: {
    ApplicationConfirmationRequired: 'Application confirmation required',
    CompanyNameRequired: 'Company name is required',
    UrlTokenRequired: 'URL is required',
    UrlTokenUnavailable: 'Sorry, that URL is already taken',
    CompanyNameUnavailable: 'Sorry, that company name is already taken',
    UrlTokenAlphanumeric: 'You can only use letters and numbers',
    LinkedinCompanyIdRequired: 'LinkedIn Company ID is required',
    LinkedinCompanyUrlRequired: 'LinkedIn Company URL is required',
    LinkedinCompanyId11Numeric:
      'LinkedIn Company IDs must be 11 numbers or less',
    LinkedinApplyRequired: 'A Linkedin Easy Apply selection is required',
    ImageFormat: 'You may only upload a .jpg, .png, or .gif file.',
    LogoHrefInvalid:
      'Logo link must be a valid URL (be sure to include http:// or https://)',
    CreatedAd: {
      Validation: {
        AdTitleRequired: 'Name is required',
        UniqueAdTitleRequired:
          'This name is already in use. Please use another.',
        PostTitleRequired: 'Title is required',
        DescriptionRequired: 'Content is required',
      },
    },
    Index: {
      ScheduledForDeletion:
        'Your job board ad is scheduled for removal. Please allow up to 24 hours for the removal to take effect.',
      Error: {
        DeletionFailed:
          'Sorry, we ran into a problem removing your ad. Please try again.',
      },
    },
    Confirm: {
      Error: 'An error occurred posting your ad. Please try again.',
    },
    Public: {
      InvalidUrl: 'Invalid URL',
    },
    Embed: {
      UrlUpdated: 'Your URL has been updated successfully',
      ApplicationConfirmationUpdated:
        'Your application confirmation has been updated successfully',
      LinkToSaved: 'Your links have been updated successfully.',
      HideViewAllSetting:
        "Your hide 'View All Jobs' setting has been updated successfully.",
      Responsive:
        "The 'responsive' setting on your job board has been updated successfully.",
      NoAutoScroll:
        "The 'No Auto Scroll' setting on your job board has been updated successfully.",
      Accessible:
        "The 'Accessible' setting on your job board has been updated successfully.",
      AutoLoad:
        "The 'auto-load' setting on your job board has been updated successfully.",
      Validation: {
        UrlRequired: 'URL required to generate embed code',
        UrlMustBeSet: 'The URL for your job board must be set.',
      },
    },
  },
  JobApplication: {
    TitleRequired: 'Title is required',
    LocationRequired: 'Location is required',
    ContentRequired: 'Content is required',
    UnsavedChanges:
      'This page is asking you to confirm that you want to leave - data you have entered may not be' +
      ' saved.',
  },
  General: {
    LeavingPageWithUnsavedChanges:
      "You haven't saved your changes! Your changes will be lost if you leave this page without saving.",
  },
  InterviewPlan: {
    NoUnassignedAttributes:
      'This interview plan includes all Skills, Personality, Qualifications and Details of your Target Candidate.',
    Validation: {
      NameIsRequired: 'Name is required',
      DescriptionIsRequired: 'Description is required',
      TimeCandidateMustBeANumber: 'Time / Candidate must be a number.',
      InvalidNumber: 'Invalid number',
    },
    Confirm: {
      DeleteStep: 'Are you sure you want to delete this step?',
    },
    Error: {
      SavingPlan:
        'Sorry, there was an error saving your plan! Please try again.',
    },
  },
  InterviewKit: {
    Error: {
      Generic:
        "Error updating your interview kit. Please try again; if that fails, <a href='https://support.greenhouse.io/direct'>contact our support team</a>.",
    },
  },
  MyInfo: {
    AllowlistUpdated: 'Your email allowlist has been updated.',
    Validation: {
      CurrentPasswordRequired: 'You must enter your current password.',
    },
  },
  EditUser: {
    PasswordResetPending: 'Sending instructions...',
    PasswordResetSent: 'Password reset instructions were emailed.',
    InvitationPending: 'Resending invite...',
    InvitationSent: 'Invitation was emailed.',
  },
  Dashboard: {
    ReopenPending: 'Re-opening job...',
  },
  Login: {
    EmailRequired: 'Enter your email.',
    PasswordRequired: 'Enter your password.',
    InvitationExpired:
      'Your link has expired. To access your account, ask your Site Admin to resend your invitation email.',
    AlreadyInvited:
      'You need to complete your registration by clicking the link in your invitation email before you can log in.',
    UserLocked: 'Your account has been locked. Reset your password to log in.',
    InvalidCredentials: 'Invalid email or password. Please try again.',
    TooManyRequests:
      'You have attempted to log in too many times in a row.  Please try again later.',
    Error: 'There was an error trying to log you in.',
  },
  ResetPassword: {
    ErrorChangingPassword: 'There was an error trying to change your password.',
    ErrorSendingEmail:
      'There was an error trying to send your password reset instructions.',
  },
  Interviews: {
    CalendarTypeRequired: 'Calendar type is required',
    EndTimeInvalid: 'End time must be after start time',
    iCal: {
      DateRequired: 'Date is required',
      StartTimeRequired: 'Start time is required',
    },
  },
  Sourcing: {
    TypeRequired: 'You must select a type',
    NameRequired: 'You must provide a name',
  },
  Common: {
    Validation: {
      RequiredField: 'Required field',
      Duplicate: 'This name is already in use',
      FieldEmpty: 'A value must be provided',
      FirstNameRequired: 'First name is required.',
      LastNameRequired: 'Last name is required.',
      AgencyNameRequired: 'Agency name is required',
      SubjectRequired: t('messages.common.validation.subject_required'),
      BodyRequired: t('messages.common.validation.body_required'),
      InvalidEmailAddress: 'Invalid email address',
      InvalidCandidateEmailAddress: t(
        'messages.common.validation.invalid_candidate_email_address'
      ),
      InvalidEmailUpdateProfile:
        "Invalid email address. Please fix this person's email address on their profile and try again.",
      InvalidCC: 'One or more invalid e-mail addresses',
      NewPasswordEmpty: 'Your must type a new password.',
      IncorrectPasswordConfirmation: "Passwords don't match.",
      OpeningsRequired: 'Number of openings is required.',
      JobNameRequired: 'Job name is a required field',
      OfficeRequired: 'Office is a required field',
      ReqIdRequired: 'Requisition ID is a required field',
      OpeningIdRequired: 'Opening ID is a required field',
      JobLevelRequired: 'Job Level is a required field',
      DepartmentRequired: 'Department is a required field',
      OrganizationRequired: 'Company/Organization is required',
      DescriptionRequired: 'Description is required',
      PhoneRequired: 'Phone is required',
      ResumeRequired: 'A resume is required',
      CoverLetterRequired: 'A cover letter is required',
      AttachmentRequired: 'An attachment is required',
      LocationRequired: 'Location is required',
      InvalidLocation: 'We could not find that location',
      DateInvalid: 'Enter a valid date of the format ',
    },
    General: {
      ConnectionError:
        "It looks like we're having trouble connecting to the server, please try again later.",
    },
  },
  CustomOptions: {
    Edit: {
      EnterFieldName: 'Enter a field name',
      SelectFieldType: 'Select a field type',
      SelectJobRangeField: 'Select a job range field',
      SelectCustomField: 'Select a custom field',
      ValidateToken: 'Token may only contain letters, numbers, and underscores',
      EnterOption: 'Enter an option',
      RejectionFieldConfiguration: 'At least 1 option must be selected',
      Required: 'This field is required',
    },
    DeleteSourceConfirm: 'Are you sure you want to delete this custom source?',
    DeleteTagConfirm: 'Are you sure you want to delete this custom tag?',
    DeleteReferrerConfirm:
      'Are you sure you want to delete this custom referrer?',
    DeleteRejectionReasonConfirm:
      'Are you sure you want to delete this custom rejection reason?',
    CandidateAttrUpdateSuccessful:
      'Your custom candidate attribute was updated successfully',
  },
  StackOverflow: {
    InvalidCompanyName: 'A company name is required',
    InvalidCompanyURL: 'A valid company URL is required',
    TechnologyMissing: 'You must specify at least 1 technology',
    MaxTechnologies: 'You can only specify 5 technologies',
    InvalidCity: 'A city is required',
    InvalidState: 'A state is required',
  },
  ZipRecruiter: {
    BlankCategory: 'You need to select a category to post this job to',
    BlankPostalCode: 'Postal Code is required',
  },
  Dice: {
    SkillsRequired: 'Skills are required',
    PayRateRequired: 'Pay rate is required',
    CityRequired: 'City is required',
    StateRequired: 'State is required',
    PostalCodeNumeric: 'Postal code must be numeric',
    AreaCodeNumeric: 'Area code must be numeric',
    TaxTermRequired: 'At least 1 tax term is required',
  },
  Organization: {
    NameRequired: 'Name is required',
    NameAlreadyInUse: 'This name is already in use',
    NewLocationRequired: 'You must choose where to move the existing jobs',
  },
  ApiCredential: {
    TypeRequired: 'A Type is required',
    CredentiableRequired: 'A Client Application is required',
  },
  Form: {
    InvalidFormat: 'Invalid file format selected. Please try again.',
    ResumeAttached:
      'The resume you chose has been attached. Please make any changes and submit.',
    ResumeParsedAndAttached: 'Resume successfully parsed',
  },
  WorkdaySyncServiceCalls: {
    IdXpathTokenRequired:
      'The @type must be provided for the Workday service call.',
  },
  Uploaders: uploaderMessages(),
  Virus: {
    FileScanningWarning:
      'This attachment is still being scanned, do you wish to still download?',
    VirusDetectedWarning:
      'We have detected a potentially malicious or dangerous attachment, do you still wish to download?',
    VirusErrorWarning:
      'The virus scan of this document failed. It could contain harmful content. Would you still like to download?',
    FileSkippedWarning:
      'Sorry, we are unable to scan this file for viruses. The file exceeds the maximum size that we scan. Download anyway?',
  },
  Error: {
    Generic: 'Sorry, but an error has occurred',
    TabContent:
      "Sorry, we ran into an error trying to load this information. Try refreshing the page, or <a href='https://support.greenhouse.io/direct'>contact our support team</a> for further support.",
    NoPermission: 'You do not have permission to perform that action',
  },
  ClipBoard: {
    CopyLink: 'Copy this link to your clipboard',
  },
  Otp: {
    BlankCode: 'Enter your code',
    InvalidCode: 'You entered an invalid or expired code. Please try again.',
    CodeSent: 'A new code was sent to your phone',
    Throttled: 'Please wait a few minutes before requesting another code.',
    GenericError: 'Something went wrong. Please try again.',
  },
  SupplementalInfo: {
    JobPostRequired: 'Job post required.',
    SubmissionLinkRequired: 'Submission link is required.',
  },
} as const;

export default Message;
