// TODO: Duplicated from app/assets/javascripts/legacy/content_security_policy_helper.js
import Handlebars from './handlebars';

/**
 * Inline javascript tags open us up to XSS vulnerabilities.
 * To avoid that, we instead create html tags marked up with js data (generated by content_security_policy_helper.rb).
 * This script retrieves and parses the data and assigns it to variables,
 * preserving the original scope and values.
 *
 * =====================
 * Usage Types
 * =====================
 *
 * 1. There are several types that are handled:
 *    a. strings
 *    b. objects (also handles all levels of nesting)
 *    c. handlebars
 *
 * 2. To see how to generate html markup that is utilized for this script
 *    see comments in content_security_policy_helper.rb
 *
 * side note: This file is not using any jQuery in order to be able to extract
 * inline javascripts (i.e. rollbar) to a separate file which is loaded before
 * jQuery is available.
 */

const ContentSecurityPolicyHelper = function (selector) {
  const key = selector.getAttribute('data-key');
  let value = selector.getAttribute('data-value');
  const type = selector.getAttribute('data-type');
  const handlebars = selector.getAttribute('data-handlebars');

  function buildObject(keys) {
    let parentObject = window;
    let key;

    for (let i = 0; i < keys.length; i++) {
      key = keys[i];

      parentObject[key] = parentObject[key] || {};
      parentObject = parentObject[key];
    }

    return parentObject;
  }

  function setValue(key, object) {
    object = object || window;

    if (handlebars) {
      value = Handlebars.compile(value);
      object[key] = value;
    } else {
      switch (type) {
        case 'json':
          object[key] = JSON.parse(value);
          break;
        case 'string':
          if (value === 'null') {
            object[key] = null;
          } else {
            object[key] = value.toString();
          }
          break;
        case 'boolean':
          object[key] = JSON.parse(value);
          break;
        case 'number':
          object[key] = parseFloat(value);
          break;
        case 'null':
          object[key] = null;
          break;
        default:
          object[key] = value;
      }
    }
  }

  this.setObject = function () {
    // Objects are built by iterating through a string that is split by '.'
    // (ex: "Config.EmailTypes.nameTokenMap")
    // This also works for strings that aren't separated by dots (i.e. "current_user_id")
    const keys = key.split('.');
    const lastKey = keys.pop();
    const parentObject = buildObject(keys);

    setValue(lastKey, parentObject);
  };
};

function forEachElement(elements, fn) {
  for (let i = 0; i < elements.length; i++) {
    fn(elements[i]);
  }
}

function addClass(el, className) {
  if (el.classList) {
    el.classList.add(className);
  } else {
    el.className += ' ' + className;
  }
}

// Only iterating through selectors without a class of processed because
// of cases where loading multiple partials in templates where the data has already been processed.
const findAndConvertMetadata = function () {
  const jsContainer = document.querySelectorAll('.js-metadata:not(.processed)');

  forEachElement(jsContainer, function (el) {
    const contentSecurityPolicyHelper = new ContentSecurityPolicyHelper(el);

    contentSecurityPolicyHelper.setObject();

    addClass(el, 'processed');
  });
};

export { findAndConvertMetadata };
